<template>
  <div>
    <!-- 切换分类 -->
    <div class="category-bar flex-row-between-center">
      <div :class="currentStatusIndex === index ? 'category-active category-btn' : 'category-btn'" v-for="(item, index) in statusList" :key="index" @click="handleChangeStatus(index)">{{ item.name }}</div>
    </div>

    <!-- 列表 -->
    <div class="list">
      <!-- 列表项目 -->
      <div class="list-item" v-for="(item, index) in list" :key="index" @click="handleSwitchPage(item.activitySignupID)">
        <!-- 内容块 -->
        <div class="body">
          <!-- 标题 -->
          <div class="title-line flex-row-between-center">
            <span class="title one-line">{{item.activity.name}}</span>
            <span :class="item.statusClass + ' status'">{{item.statusLabel}}</span>
          </div>
          <!-- 内容 -->
          <div class="content">
            <div>活动时间：{{item.activity.activityTime}}</div>
            <div v-if="item.status === 'Cancel'">取消时间：{{item.canceledAt}}</div>
            <div v-if="item.signupAt">报名时间：{{item.signupAt}}</div>
            <div v-if="item.signinAt">签到时间：{{item.signinAt}}</div>
            <div>活动费用：{{item.activity.isFree ? "免费" : item.activity.cost + "元" }}</div>
          </div>
          <!-- 按钮行 -->
          <div class="btn-line flex-row-end-center" v-if="['Signup', 'End'].includes(item.status)">
            <div v-if="item.status === 'Signup'" class="signin-btn">签到</div>
            <div v-if="item.status === 'Signup'" class="cancel-btn" @click.stop="handleCancelSignup(item.activityID)">取消</div>
            <div v-if="item.status === 'End'" class="evaluate-btn" @click.stop="handleEvaluate(item.activityID)">评价</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 底标 -->
    <div class="no-more" v-if="isEnd">没有更多内容了</div>
  </div>
</template>

<script>
import page from '@/mixins/page'
import { MessageBox, Toast } from 'mint-ui'

export default {
  name: 'UserActivityList',

  data () {
    return {
      currentStatusIndex: 0,
      currentStatus: 'All',
      statusList: [
        { name: '全部', status: 'All' },
        { name: '已报名', status: 'Signup' },
        { name: '已签到', status: 'Signin' },
        { name: '已结束', status: 'End' }
      ],

      list: [],
      keyword: '',
      currentPage: 1,
      pageSize: 8,
      isEnd: false
    }
  },

  mixins: [page],

  created () {
    this.pageSize = Math.ceil((this.getWindowHeight() - 30 - 32) / 103)
    this.getData()
  },

  beforeRouteLeave (to, from, next) {
    from.meta.keepAlive = false
    next()
  },

  methods: {
    // 获取数据
    getData () {
      this.api.getAllActivitySignup({ currentPage: this.currentPage, pageSize: this.pageSize, keyword: this.keyword, status: this.currentStatus }).then(res => {
        if (res.data.length === 0 || res.data.length < this.pageSize) this.isEnd = true
        for (const item of res.data) {
          switch (item.status) {
            case 'Signup':
              item.statusClass = 'signup'
              item.statusLabel = '待签到'
              break
            case 'Signin':
              item.statusClass = 'signin'
              item.statusLabel = '已签到'
              break
            case 'Cancel':
              item.statusClass = 'cancel'
              item.statusLabel = '已取消'
              break
            case 'NotSignin':
              item.statusClass = 'not-signin'
              item.statusLabel = '未签到'
              break
            case 'End': case 'Evaluation':
              item.statusClass = 'end'
              item.statusLabel = '已结束'
              break
          }
        }
        this.list = [...this.list, ...res.data]
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.getData() })
      })
    },
    // 切换分类
    handleChangeStatus (index) {
      if (this.currentStatusIndex === index) return
      this.currentStatusIndex = index
      this.currentStatus = this.statusList[index].status
      this.keyword = ''
      this.initPage()
      this.getData()
    },
    // 切换页面
    handleSwitchPage (id) {
      this.$router.push({ path: '/user/activity-detail', query: { id } })
    },
    // 触底函数
    hitBottom () {
      if (this.isEnd) return
      this.currentPage++
      this.getData()
    },
    // 搜索
    handleSearch () {
      this.initPage()
      this.getData()
    },
    // 取消报名
    handleCancelSignup (id) {
      MessageBox.confirm('是否确认取消报名？').then(() => {
        this.cancelSignup(id)
      }).catch(() => {})
    },
    // 确定取消报名
    cancelSignup (id) {
      this.api.cancelSignupActivity({ id }).then(res => {
        Toast(res.msg || '取消报名成功')
        this.initPage()
        this.getData()
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.cancelSignup() })
      })
    },
    // 活动评价
    handleEvaluate (id) {
      this.$router.push({ path: '/user/activity-evaluation', query: { id } })
    }
  }
}
</script>

<style scoped>
.category-bar {
  padding: 0 15px;
  font-size: 14px;
}
.category-btn {
  width: calc((100vw - 30px) / 4 - 5px);
  height: 40px;
}
.list {
  margin: 15px;
}
.list-item {
  border: 1px solid #dddddd;
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 2px 2px #eeeeee;
}
.list-item + .list-item {
  margin-top: 10px;
}

.list-item > img {
  object-fit: cover;
  width: 100%;
  max-height: 300px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.body {
  padding: 5px 15px;
  font-size: 15px;
  color: var(--normal-font-color);
}

.title-line {
  font-weight: bold;
  padding: 10px;
  border-bottom: 1px solid #f5f5f5;
}
.title {
  width: 80%;
}
.status {
  font-size: 14px;
}
.signup {
  color: var(--main-color);
}
.signin {
  color: #42b983;
}
.cancel {
  color: #aaa;
}
.end {
  color: #dd6975;
}
.not-signin {
  color: #aaa;
}

.content {
  padding: 10px;
  font-size: 13px;
  color: #999;
}

.btn-line {
  padding: 10px 0;
  border-top: 1px solid #f5f5f5;
  font-size: 14px;
}
.btn-line > div {
  margin-left: 10px;
  padding: 5px 20px;
  border-radius: 5px;
}
.signin-btn {
  background: var(--main-color);
  color: #fff;
}
.cancel-btn {
  background: #eee;
  color: #888;
}
.evaluate-btn {
  background: #dd6975;
  color: #fff;
}
</style>
